import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Button } from 'react-bootstrap';

import '../../generic-page.scss';

const CSPVPforSiegePage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Siege Playbook</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/category_siege.png"
            alt="Siege Playbook"
          />
        </div>
        <div className="page-details">
          <h1>Siege Playbook</h1>
          <h2>A guide for Siege/Terror teams in PVP.</h2>
        </div>
      </div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          There is an important distinction to be made between siege and terror
          comps. Siege relies on actual siege units like Rhino or Goliath as a
          frontline, while terror focuses completely on dealing damage to the
          ship without a concrete frontline.
        </p>
        <p>
          Both teams focus on dealing direct damage to the enemy ship while more
          or less ignoring their units. These teams are strong because while
          players can gear their units with high resistances and defensive
          stats, they cannot gear their ship with defensive stats. As a result,
          even against well-geared whales, siege/terror has good performance.
          Furthermore, siege/terror have very quick games, which allows for
          quicker climbing in RTA, where traditional comps can very often
          stretch into 3 minute slugfests.
        </p>
        <p>
          If you have any questions or feedback, please don't be afraid to dm
          Adepht who is the author of the guide <strong>adepht#0561</strong>.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
        <SectionHeader title="Siege Playbook" />
        <p>
          Due to the guide being massive, very detailed and also to allow the
          author to easily edit it, it will be kept in the original form as a
          google doc.
        </p>
        <p>Link to the guide:</p>
        <Button
          variant="primary"
          href="https://docs.google.com/document/d/1SJJyj-8KrzUq0Sdy8mW2P1y2I15S4OQRJqlmBaxMWIQ/edit"
          target="_blank"
          rel="noreferrer"
        >
          Siege Playbook
        </Button>
      </div>
    </DashboardLayout>
  );
};

export default CSPVPforSiegePage;

export const Head: React.FC = () => (
  <Seo
    title="Siege Playbook | Counter Side | Prydwen Institute"
    description="A guide for Siege/Terror teams in PVP."
  />
);
